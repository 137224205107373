import { dataUser } from "config/constants/auth";
import { accountProps } from "./type";

export const fetchUserData = async (account: string,): Promise<{dataUser: accountProps }> => {
   
    try {
        const filterDataUser = dataUser?.find((item) => item?.account.toLowerCase() === account.toLowerCase())
        
        if(filterDataUser){
            return { 
                dataUser: filterDataUser
            }
    }   
        return { dataUser: filterDataUser }
    }
    catch(error) {
        console.log(error)
        return {
            dataUser: null
            }
        }
}