
import { createReducer } from '@reduxjs/toolkit'
import { fecthdataUser } from './actions';
import { accountProps } from './type';


interface globalStateAccount {
    dataUser: accountProps
}
export const initialState: globalStateAccount = {
    dataUser: null
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fecthdataUser, (state, action) => {
      state.dataUser = action.payload.dataUser
    })
)
