import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 65 65" fill="none" {...props}>
        <rect y="32.0146" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect y="38.1592" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect y="44.4648" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect y="50.6094" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect y="56.915" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect y="63.0596" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="6.14453" y="32.0146" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="6.14453" y="38.1592" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="6.14453" y="44.4648" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="6.14453" y="50.6094" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="6.14453" y="56.915" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="6.14453" y="63.0596" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="12.2881" y="32.0146" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="12.2881" y="38.1592" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="12.2881" y="44.4648" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="12.2881" y="50.6094" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="12.2881" y="56.915" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="12.2881" y="63.0596" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="18.4321" y="32.0146" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="18.4321" y="38.1592" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="18.4321" y="44.4648" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="18.4321" y="50.6094" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="18.4321" y="56.915" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="18.4321" y="63.0596" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="24.5767" y="32.0146" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="24.5767" y="38.1592" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="24.5767" y="44.4648" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="24.5767" y="50.6094" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="24.5767" y="56.915" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="24.5767" y="63.0596" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="30.7207" y="32.0146" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="30.7207" y="38.1592" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="30.7207" y="44.4648" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="30.7207" y="50.6084" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="30.7207" y="56.915" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="30.7207" y="63.0596" width="1.94029" height="1.94029" rx="0.970147" fill="#F9A138"/>
        <rect x="9.70166" width="55.2984" height="55.1367" rx="10" fill="#F9A138"/>
    </Svg>
  );
};

export default Icon;
