import { createAction } from '@reduxjs/toolkit'
import { ListToken, ListNFTInfo, TokenOwnerProps, ListInvestPaymentTemp, totalListingProps, TokenProps, ListWrapppedItems, ListUserBalance, ListUserBalanceAsset } from './type'

export const getLoading = createAction<{isLoading:boolean}>('asset/getLoading')
export const fetchAssetItems = createAction<ListWrapppedItems>('asset/fetchAssetItems')
export const getUserBalance = createAction<ListUserBalance>('asset/getUserBalance')
export const getIntervestTermRate = createAction<{interVestTermRate: number}>('asset/getIntervestTermRate')
export const getTotalListing = createAction<totalListingProps>('asset/getTotalListing')
export const fetchPageNumber= createAction<{pagenumbercount:number}>('asset/fetchPageNumber')

export const getTotalSupplyOfId = createAction<{totalSupplyOfId:number}>('asset/fetchTotalSupplyOfId')
export const getTokenOwner = createAction<TokenOwnerProps>('asset/getTokenOwner')
export const getTokenInfo = createAction<{etfInfo: TokenProps[]}>('asset/getTokenInfo')
export const getNftInfo = createAction<ListNFTInfo>('asset/getNftInfo')
export const getListInvestPayment = createAction<ListInvestPaymentTemp>('asset/getListInvestPayment')
export const getTotalClientOwn = createAction<{totalClientOwn:number}>('asset/getTotalClientOwn')
export const getBalanceVndc = createAction<{balanceOf:number}>('asset/getBalanceVndc')
