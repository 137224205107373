import { accountProps } from "state/auth/type";

export const dataUser: accountProps[] = [
    {
        account: '0x0f21FA8A0019e6D0c49705c81B30430993364c4B',
        type: 1,
        name: 'Quỹ đầu tư khởi nghiệp sáng tạo FUNDGO',
        image: 'images/logo-fundgo.png'
    },
    {
        account: '0x0bfC9646D6F3e6790F071A3fa80f0AA14dF16e2A',
        type: 2,
        name: 'JADELABS',
        image: 'images/ccqFundgo/jadlabs.png'
    },

]