import { createReducer } from '@reduxjs/toolkit'
import { fetchPageNumberPaymentDetail, listPaymentAction } from "./actions"
import { Items } from './type'

interface global {
  listPaymentDetail: Items[],
  pagenumbercount:number
}
export const initialState: global = {
  listPaymentDetail:[],
  pagenumbercount:0,

}
export default createReducer(initialState, (builder) =>
  builder
    .addCase(listPaymentAction , (state, action) => {
      state.listPaymentDetail = action.payload.listPaymentDetail
    })
    .addCase(fetchPageNumberPaymentDetail , (state, action) => {
      state.pagenumbercount = action.payload.pagenumbercount
    })
)