
import contracts from 'config/constants/contracts';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from 'state';
import { getAddress } from 'utils/addressHelpers';
import { fecthdataUser } from "./actions";
import { fetchUserData } from "./fetchData";

export const GetWhitelist = () => {
    const result = useSelector<AppState, AppState['authReducer']>((state) => state.authReducer)
    const isWhitelist = result
    return isWhitelist
}

export const UseCoreLogin = () => {
    const { account, chainId } = useActiveWeb3React()
    const dispatch = useDispatch<AppDispatch>()
    useEffect(() => {
        const getWhitelist = async () => {
            try {
                const resultFetchUserData = await fetchUserData(account)
                dispatch(fecthdataUser({ dataUser: resultFetchUserData?.dataUser }))
            } catch (e) { 
                console.log(e)
            }
        }
        if ( account?.length ) {
            getWhitelist()
        } else {
            dispatch(fecthdataUser({ dataUser: null }))
        }

    }, [account, chainId, dispatch])
}