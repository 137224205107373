import { createReducer } from '@reduxjs/toolkit'
import { getContractAddress } from './actions'

interface global {
    contractAddress:string
}
export const initialState: global = {
    contractAddress:"",
}
export default createReducer(initialState, (builder) =>
  builder
    .addCase(getContractAddress, (state, action) => {
      state.contractAddress = action.payload.contractAddress
    })
)