import { Button, Grid, Message, MessageText, Modal, ModalTitle, Text, Heading } from '@pancakeswap/uikit'
import { useLocalNetworkChain, useActiveChainId } from 'hooks/useActiveChainId'
import { useTranslation } from '@pancakeswap/localization'
import { useSwitchNetwork, useSwitchNetworkLocal } from 'hooks/useSwitchNetwork'
import Image from 'next/image'
import useAuth from 'hooks/useAuth'
import { useMenuItems } from 'components/Menu/hooks/useMenuItems'
import { useRouter } from 'next/router'
import { getActiveMenuItem, getActiveSubMenuItem } from 'components/Menu/utils'
import { useAccount, useNetwork } from 'wagmi'
import { useMemo } from 'react'
import { ChainId } from '@pancakeswap/sdk'
import styled from 'styled-components'
import Dots from '../Loader/Dots'

const LIST_CHAINS = [
    {
        id: 56,
        name: 'BNB Smart Chain',
        testnet: false
    },
    {
        id: 10001,
        name: 'ETHW Chain',
        testnet: false
    },
    {
        id: 1975,
        name: 'ONUS Chain',
        testnet: false
    },
    {
        id: 1945,
        name: 'ONUS Testnet',
        testnet: true
    },
    {
        id: 97,
        name: 'BSC Testnet',
        testnet: true
    }
]

// Where chain is not supported or page not supported
export function UnsupportedNetworkModal({ pageSupportedChains }: { pageSupportedChains: number[] }) {
    const { switchNetworkAsync, isLoading, canSwitch, pendingChainId } = useSwitchNetwork()
    const switchNetworkLocal = useSwitchNetworkLocal()
    const { isWrongNetwork, chain } = useActiveChainId()

    const { chains } = useNetwork()
    // const chainId = useLocalNetworkChain() || ChainId.BSC
    const chainId = useLocalNetworkChain()

    const foundChain = useMemo(() => (
        chains.length > 0 ? chains.find((c) => c.id === (chainId ?? chain?.id)) : ({
            id: chainId,
            name: LIST_CHAINS.find(e => e.id === (chainId ?? ChainId.BSC))?.name
        })), [chainId]
    )

    const { isConnected } = useAccount()
    const { logout } = useAuth()
    const { t } = useTranslation()
    const menuItems = useMenuItems()
    const { pathname, push } = useRouter()
    const title = useMemo(() => {
        const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
        const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })
        return activeSubMenuItem?.label || activeMenuItem?.label
    }, [menuItems, pathname])

    const supportedMainnetChains = useMemo(
        () => chains.length > 0 ? chains.filter((e) => !e.testnet && pageSupportedChains?.includes(e.id)) :
            LIST_CHAINS.filter((e) => !e.testnet && pageSupportedChains?.includes(e.id)),
        [chains, pageSupportedChains],
    )

    function handleClick() {
        logout().then(() => {
            // switchNetworkLocal(ChainId.BSC)
            if (pageSupportedChains.includes(ChainId.BSC)) {
                switchNetworkLocal(ChainId.BSC)
            } else {
                switchNetworkLocal(ChainId.ONUS)
            }
        })
    }

    function handlePush() {
        if (chainId === ChainId.ONUS || chainId === ChainId.ETHW_MAINNET || chainId === ChainId.ONUS_TESTNET) {
            // push('/mysterybox')
            push('/farms')
        } else {
            push('/')
        }
    }

    function handleClickStayOn() {
        logout().then(() => {
            handlePush()
            switchNetworkLocal(foundChain?.id)
        })
    }

    return (
        <CsModal title={t('Kiểm tra network của bạn')} onDismiss={handleClick} headerBackground="gradientCardHeader">
            <CsGrid style={{ gap: '16px' }}>
                <CsText>
                    {t('Hiện tại trang này chỉ hỗ trợ trên', { feature: typeof title === 'string' ? title : 'trang này' })}{' '}
                    {supportedMainnetChains?.map((c) => c.name).join(', ')}
                </CsText>
                <div style={{ textAlign: 'center' }}>
                    <Image
                        layout="fixed"
                        width="215px"
                        height="215px"
                        src="/images/check_netword.png?version=1.1"
                        alt="check your network"
                    />
                </div>
                <Message variant="warning">
                    <MessageText>{t('Vui lòng chuyển network để tiếp tục')}</MessageText>
                </Message>
                <Button
                    variant='secondary'
                    onClick={handleClickStayOn}
                >
                    {t('Ở lại %chain%', { chain: foundChain?.name })}
                </Button>
                {canSwitch ? (
                    <Button
                        isLoading={isLoading}
                        onClick={() => {
                            if (supportedMainnetChains.map((c) => c.id).includes(chainId)) {
                                switchNetworkAsync(chainId)
                            } else {
                                // eslint-disable-next-line no-lonely-if
                                if (!supportedMainnetChains.map((c) => c.id).includes(ChainId.BSC)) {
                                    if (pendingChainId !== undefined && pendingChainId !== ChainId.BSC) {
                                        switchNetworkAsync(ChainId.BSC)
                                    }
                                    else {
                                        // eslint-disable-next-line no-lonely-if
                                        if (isWrongNetwork) {
                                            logout().then(() => {
                                                switchNetworkLocal(pageSupportedChains.includes(chain?.id) ? chain?.id : ChainId.ONUS)
                                                // switchNetworkLocal(ChainId.ONUS)
                                            })
                                        }
                                        else {
                                            switchNetworkAsync(pageSupportedChains.includes(chain?.id) ? chain?.id : ChainId.ONUS)
                                            // switchNetworkAsync(ChainId.ONUS)
                                        }
                                    }
                                } else {
                                    // eslint-disable-next-line no-lonely-if
                                    if (isWrongNetwork) {
                                        logout().then(() => {
                                            switchNetworkLocal(ChainId.BSC)
                                        })
                                    }
                                    else {
                                        switchNetworkAsync(ChainId.BSC)
                                    }
                                }
                            }
                        }}
                    >
                        {isLoading ? <Dots>{t('Đổi network của ví')}</Dots> : t('Đổi network của ví')}
                    </Button>
                ) : (
                    <Message variant="danger">
                        <MessageText>{t('Không thể chuyển network được. Vui lòng thử lại')}</MessageText>
                    </Message>
                )}
                {isConnected && (
                    <Button
                        variant="secondary"
                        onClick={() =>
                            logout().then(() => {
                                switchNetworkLocal(ChainId.BSC)
                            })
                        }
                    >
                        {t('Ngắt kết nối ví')}
                    </Button>
                )}
                {/* <Button
          variant='secondary'
           onClick={handleClickStayOn}
          >
            {t('Stay on %chain%' , { chain: foundChain?.name })}
          </Button> */}
            </CsGrid>
        </CsModal>
    )
}
const CsModal = styled(Modal)`
    ${ModalTitle}{
      @media screen and (max-width: 500px) {
        padding-top:30px;
      }
    }
    ${Heading}{
      font-weight:800;
      font-size:22px;
    }
    overflow-y:hidden;
`
const CsGrid = styled(Grid)`
  width: 400px;
  @media screen and (max-width: 600px) {
    max-width: 316px;
  }
`
const CsText = styled(Text)`
    width: 100%;
    margin-top: 12px;
    text-align:center;
    @media screen and (max-width: 600px) {
      text-align:left;
      font-size: 15px;
    }
`