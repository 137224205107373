import { createAction } from '@reduxjs/toolkit'
import { ListListing, ListNFTInfo, ListToken, ListUserBalance, ListWrapppedItems, itemListing } from 'state/asset/type'
import { BalanceOrder, ListItem, ListResultBalance } from './type'

export const fetchStatusLoading = createAction<{ isLoading: boolean }>('order/fetchStatusLoading')
export const fetchOrderItems = createAction<ListWrapppedItems>('order/fetchOrderItems')
export const getUserBalance = createAction<ListUserBalance>('order/getUserBalance')
export const getBalanceAction = createAction<ListResultBalance>('order/getBalanceAction')
export const fetchPageNumber = createAction<{ pagenumbercount: number }>('order/fetchPageNumber')
export const actionDispatchMessage = createAction<{ messageHash: string }>('order/messageActions');

export const getListItems = createAction<ListItem>('order/getListItems')
export const getTokenInfo = createAction<{ listToken: ListToken }>('order/getTokenInfo')
export const getListListing = createAction<{ listListing: itemListing[] }>('order/getListListing')
export const getIntervestTermRate = createAction<{ interVestTermRate: number }>('order/getIntervestTermRate')
export const getNftInfo = createAction<ListNFTInfo>('order/getNftInfo')
