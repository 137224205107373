import { createReducer } from '@reduxjs/toolkit'
import { AssetItemsWidthBalance, ListToken, NFTInfoProps, WrappedItems, itemListing } from 'state/asset/type'
import {
    fetchOrderItems,
    fetchPageNumber,
    fetchStatusLoading,
    getIntervestTermRate,
    getListItems,
    getListListing,
    getNftInfo,
    getTokenInfo,
    getUserBalance,
    actionDispatchMessage,
    getBalanceAction
} from './actions'
import { BalanceOrder, Item } from './type'

interface global {
    isLoading: boolean,
    listWrappedItem: WrappedItems[],
    listUserBalance: AssetItemsWidthBalance[],
    listUserBalanceOrder: BalanceOrder[],
    messageHash: string,
    listItems: Item[],
    pagenumbercount: number,
    listToken: ListToken,
    listListing: itemListing[],
    intervestTermRate: number,
    listNftInfo: NFTInfoProps[],
}

export const initialState: global = {
    isLoading: false,
    listWrappedItem: [],
    listUserBalance: [],
    listUserBalanceOrder: [],
    messageHash: null,
    listItems: [],
    pagenumbercount: 0,
    listToken: null,
    listListing: [],
    intervestTermRate: 0,
    listNftInfo: [],
}

export default createReducer(initialState, (builder) =>
    builder
        .addCase(fetchStatusLoading, (state, action) => {
            state.isLoading = action.payload.isLoading
        })
        .addCase(fetchOrderItems, (state, action) => {
            state.listWrappedItem = action.payload.listWrappedItem
        })
        .addCase(getUserBalance, (state, action) => {
            state.listUserBalance = action.payload.listUserBalance
        })
        .addCase(getBalanceAction, (state, action) => {
            state.listUserBalanceOrder = action.payload?.listResultBalance
        })
        .addCase(fetchPageNumber, (state, action) => {
            state.pagenumbercount = action.payload.pagenumbercount
        })

        .addCase(getListItems, (state, action) => {
            state.listItems = action.payload?.listItems
        })
        .addCase(getTokenInfo, (state, action) => {
            state.listToken = action.payload.listToken
        })
        .addCase(getListListing, (state, action) => {
            state.listListing = action.payload.listListing
        })
        .addCase(getIntervestTermRate, (state, action) => {
            state.intervestTermRate = action.payload.interVestTermRate
        })
        .addCase(getNftInfo, (state, action) => {
            state.listNftInfo = action.payload.listNftInfo
        })
        .addCase(actionDispatchMessage, (state, action) => {
            state.messageHash = action.payload.messageHash;
        })
)