import { createReducer } from '@reduxjs/toolkit'
import { tabActiveHistory } from "./actions"

interface globalPoolRun {
    tabIndex:number
}
export const initialState: globalPoolRun = {
    tabIndex:0
}
export default createReducer(initialState, (builder) =>
  builder
    .addCase(tabActiveHistory, (state, action) => {
      state.tabIndex = action.payload.tabIndex
    })
    
)