import { ChainId, Token, WBNB } from '@pancakeswap/sdk';
import { BUSD_TESTNET, CAKE_TESTNET } from './common';

export const bscTestnetTokens = {
    wbnb: WBNB[ChainId.BSC_TESTNET],
    cake: CAKE_TESTNET,
    busd: BUSD_TESTNET,
    syrup: new Token(
        ChainId.BSC_TESTNET,
        '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
        18,
        'SYRUP',
        'SyrupBar Token',
        'https://pancakeswap.finance/',
    ),
    bake: new Token(
        ChainId.BSC_TESTNET,
        '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
        18,
        'BAKE',
        'Bakeryswap Token',
        'https://www.bakeryswap.org/',
    ),
    hbtc: new Token(ChainId.BSC_TESTNET, '0x3Fb6a6C06c7486BD194BB99a078B89B9ECaF4c82', 18, 'HBTC', 'Huobi BTC'),
    wbtc: new Token(ChainId.BSC_TESTNET, '0xfC8bFbe9644e1BC836b8821660593e7de711e564', 8, 'WBTC', 'Wrapped BTC'),
    usdc: new Token(
        ChainId.BSC_TESTNET,
        '0x2fB98DCc527c1403F92c6Bed05a50725d3005636',
        18,
        'USDC',
        'Binance-Peg USD Coin',
    ),
    runtogether: new Token(
        ChainId.BSC_TESTNET,
        '0x242a227B38f704983FF101DC7De573ED8111601e',
        18,
        'RUN ',
        'Run Together',
        'https://runtogether.net/',
    ),
    runtogetherBoxNft: new Token(
        ChainId.BSC_TESTNET,
        '0x00fbf2a59c65d495055dee6e3925ff8d7c0bdad6',
        0,
        'RTBN',
        'Run Together Box NFT',
        'https://runtogether.net/',
    ),
    vndc: new Token(
        ChainId.BSC_TESTNET,
        '0x4534E6d66ac75d80d877E3317789303707543655',
        0,
        'VNDC',
        'ONUSPegToken',
        '',
    ),
    wrappedDetalabs1: new Token(
        ChainId.BSC_TESTNET,
        '0x899b26967B620Bb63B9fDBA3c4e465C57E79b6A3',
        0,
        'TTS 0707 1000_16',
        'TTS 0707 1000_16',
        '',
    )
};