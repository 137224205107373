
import { createReducer } from '@reduxjs/toolkit';
import { AssetItemsWidthBalance, ListToken } from 'state/asset/type';
import {
    actionDispatchListTerm,
    actionsTransactionHash,
    dataChartAction,
    fetchListIntervest,
    fetchManagerItems,
    fetchPageNumber,
    fetchPageNumberDetail,
    getIntervestTermRate,
    getItemAdvanceAction,
    getListItems,
    getNumberTerm,
    getStatusLoading,
    getTotalListing,
    getUserBalance,
} from './actions';
import { AssetItemsWidthBalanceHome, Item, ListDataChart, ListTerm, WrappedItems } from './type';

interface globalStateManage {
    listItems: Item[],
    listWrappedItem: WrappedItems[],
    pagenumbercount: number,
    listUserBalance: AssetItemsWidthBalance[],
    pagenumberDetail: number,
    index: number,
    listTerm: ListTerm[]
    payItem: AssetItemsWidthBalance,
    dataChart: ListDataChart,
    transactionHash: string,
    isLoading: boolean,
    totalListing: number,
    intervestTermRate: number,
    listToken: ListToken,
    totalNumberTerm: number,
    listUserIntervest: AssetItemsWidthBalanceHome[]
}
export const initialState: globalStateManage = {
    listItems: [],
    listWrappedItem: [],
    pagenumbercount: 0,
    listUserBalance: [],
    pagenumberDetail: 0,
    index: 0,
    listTerm: [],
    payItem: null as AssetItemsWidthBalance,
    dataChart: {
        holdingChartData: [],
        averageTransferValue: 0,
        averageHoldingTime: 0
    },
    transactionHash: null,
    isLoading: false,
    totalListing: 0,
    intervestTermRate: 0,
    listToken: null,
    totalNumberTerm: 0,
    listUserIntervest: []
}

export default createReducer(initialState, (builder) =>
    builder
        .addCase(getListItems, (state, action) => {
            state.listItems = action.payload.listItems
        })
        .addCase(fetchManagerItems, (state, action) => {
            state.listWrappedItem = action.payload.listWrappedItem
        })
        .addCase(fetchPageNumber, (state, action) => {
            state.pagenumbercount = action.payload.pagenumbercount
        })
        .addCase(getUserBalance, (state, action) => {
            const { payItem } = state;
            const index = payItem?.index;
            state.listUserBalance = action.payload.listUserBalance
            if (index !== -1) {
                const filterData: AssetItemsWidthBalance = action.payload.listUserBalance.find((item) => item.index === index);
                state.payItem = filterData;
            }
        })
        .addCase(fetchPageNumberDetail, (state, action) => {
            state.pagenumberDetail = action.payload.pagenumberDetail
        })
        .addCase(fetchListIntervest, (state, action) => {
            state.listUserIntervest = action.payload.listUserIntervest;
        })

        .addCase(getItemAdvanceAction, (state, action) => {
            const { listUserBalance } = state;
            const resultListByAddress = () => {
                return Array.from(new Set(listUserBalance?.map(s => s?.nftAddress?.toLowerCase())))
                    .map((item) => {
                        const findTotalBalance = listUserBalance?.filter((it) => it?.nftAddress.toLowerCase() === item?.toLowerCase())

                        const sumTotalBalance = findTotalBalance?.reduce((prev, curr) => {
                            return prev + Number(curr?.nftBalance)
                        }, 0)

                        return {
                            item,
                            ...listUserBalance?.find((it) => it?.nftAddress.toLowerCase() === item?.toLowerCase()),
                            totalBalance: Number(sumTotalBalance)
                        }
                    }
                    )
            }
            const resultListData = resultListByAddress()
            const filterData: AssetItemsWidthBalance = resultListData.find((item) => item.nftAddress?.toLowerCase() === action.payload.nftAddress?.toLowerCase());
            state.payItem = filterData;
        })

        .addCase(actionDispatchListTerm, (state, action) => {
            state.listTerm = action.payload;
        })
        .addCase(dataChartAction, (state, action) => {
            state.dataChart = action.payload;
        })

        .addCase(getStatusLoading, (state, action) => {
            state.isLoading = action.payload.isLoading
        })
        .addCase(getTotalListing, (state, action) => {
            state.totalListing = action.payload.totalListing
        })
        .addCase(getIntervestTermRate, (state, action) => {
            state.intervestTermRate = action.payload.interVestTermRate
        })
        .addCase(getNumberTerm, (state, action) => {
            state.totalNumberTerm = action.payload.totalNumberTerm
        })
        .addCase(actionsTransactionHash, (state, action) => {
            state.transactionHash = action.payload.transactionHash
        })
)
