import { createReducer } from '@reduxjs/toolkit'
import { actionDispatchListTerm, actionDispatchMessage, actionDispatchPayItem, listItemHomeAction } from "./actions"
import { Items, ListTerm } from './type'

interface global {
    listItemHome: Items[],
    payItem: Items,
    totalDistributor: number,
    userBalanceOf: number,
    messageHash: string,
    listTerm: ListTerm[]
}
export const initialState: global = {
    listItemHome: [],
    totalDistributor: 0,
    userBalanceOf: 0,
    messageHash: null,
    payItem: null as Items,
    listTerm: [],
}

export default createReducer(initialState, (builder) =>
    builder
        .addCase(listItemHomeAction, (state, action) => {
            const index = state.payItem?.index;
            state.listItemHome = action.payload.listItemHome;
            state.totalDistributor = action.payload.totalDistributor;
            state.userBalanceOf = action.payload.userBalanceOf;
            if (index !== -1) {
                const filterData: Items = action.payload.listItemHome?.find((item) => item.index === index);
                state.payItem = filterData;
            }
        })
        .addCase(actionDispatchMessage, (state, action) => {
            state.messageHash = action.payload.messageHash;
        })
        .addCase(actionDispatchPayItem, (state, action) => {
            const { listItemHome } = state;

            const resultListByAddress = () => {
                return Array.from(new Set(listItemHome?.map(s => s?.nftAddress?.toLowerCase())))
                    .map((item, indexgroup) => {
                        const findTotalBalance = listItemHome?.filter((it) => it?.nftAddress.toLowerCase() === item?.toLowerCase())

                        const sumTotalBalance = findTotalBalance?.reduce((prev, curr) => {
                            return prev + Number(curr?.nftBalance)
                        }, 0)

                        return {
                            indexgroup,
                            item,
                            ...listItemHome?.find((it) => it?.nftAddress.toLowerCase() === item?.toLowerCase()),
                            totalBalance: Number(sumTotalBalance)
                        }
                    }
                    )
            }
            const resultListData = resultListByAddress()
            const filterData: Items = resultListData?.find((item) => item?.nftAddress === action.payload.nftAddress);
            state.payItem = filterData;
        })
        .addCase(actionDispatchListTerm, (state, action) => {
            state.listTerm = action.payload;
        })
);