
import { createReducer } from '@reduxjs/toolkit'
import { getTokenInfo, getLoading, getNftInfo, fetchAssetItems, getUserBalance, fetchPageNumber } from './actions';
import { AssetItemsWidthBalance, NFTInfoProps, TokenProps, WrappedItems } from './type';


interface globalStateClient {
    isLoading:boolean,
    listWrappedItem:WrappedItems[],
    etfInfo:TokenProps[],
    listNftInfo:NFTInfoProps[],
    listUserBalance:AssetItemsWidthBalance[],
    pagenumbercount: number,
}

export const initialState: globalStateClient = {
    isLoading:false,
    listWrappedItem:[],
    etfInfo:null,
    listNftInfo:[],
    listUserBalance:[],
    pagenumbercount: 0
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(getLoading, (state, action) => {
        state.isLoading = action.payload.isLoading
    })
    .addCase(fetchAssetItems, (state, action) => {
        state.listWrappedItem = action.payload.listWrappedItem
    })
    .addCase(getTokenInfo, (state, action) => {
        state.etfInfo = action.payload.etfInfo
    })
    .addCase(getNftInfo, (state, action) => {
        state.listNftInfo = action.payload.listNftInfo
    })
    .addCase(getUserBalance, (state, action) => {
        state.listUserBalance = action.payload.listUserBalance
    })
    .addCase(fetchPageNumber , (state, action) => {
        state.pagenumbercount = action.payload.pagenumbercount
    })
)
