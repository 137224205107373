import React from "react";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { Text } from "@pancakeswap/uikit";
import useAuth from "hooks/useAuth";
import { GetWhitelist } from "state/auth";
import { PageFullWidth, ContaierLogin, ContainerImage, CsButton, CsButtonLogout, CsFlex, CsLoginPageIcon } from "./style";

const Login = () => {
    const {account} = useActiveWeb3React()
    const isWhitelist = GetWhitelist()
    const { logout } = useAuth()
    return (
        <PageFullWidth>
            <CsFlex>
                <CsLoginPageIcon/>
                <ContaierLogin>
                    <ContainerImage>
                        <img src="/images/ccqFundgo/img_login.png?version=1.0" alt="logo"/>
                        { account ?
                            <CsButtonLogout onClick={logout}>Ngắt kết nối ví</CsButtonLogout>
                        :
                            <CsButton>Kết nối ví</CsButton>
                        }
                        { ( account && !isWhitelist ) &&
                            <Text mt="1rem" color="red">
                                Địa chỉ ví hiện tại không nằm trong whitelist. <br/> 
                                Vui lòng chuyển đổi ví khác.
                            </Text>
                        }   
                    </ContainerImage>
                </ContaierLogin>
            </CsFlex>
        </PageFullWidth> 
    )
}

export default Login