import { ContextApi } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import { MarketplaceFillIcon, MarketplaceIcon, MenuItemsType, CCQIcon, CCQFillIcon } from '@pancakeswap/uikit'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
    items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
    if (!chainId || !item.supportChainIds) {
        return item
    }
    if (item.supportChainIds?.includes(chainId)) {
        return item
    }
    return {
        ...item,
        disabled: true,
    }
}

const config: (
    t: ContextApi['t'],
    isDark: boolean,
    languageCode?: string,
    chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
        // [
        //     {
        //         label: t('Giao dịch nội bộ'),
        //         icon: MarketplaceIcon,
        //         fillIcon: MarketplaceFillIcon,
        //         href: 'https://tss.org.vn/chung-chi-quy',
        //         type: 1,
        //         showItemsOnMobile: false,
        //         items:
        //             [
        //                 {
        //                     label: t('Giao dịch nội bộ'),
        //                     href: 'https://tss.org.vn/chung-chi-quy',
        //                     type: 1,
        //                     supportChainIds: [ChainId.ONUS, ChainId.ONUS_TESTNET]
        //                 },
        //             ].map((item) => addMenuItemSupported(item, chainId))
        //     },
        //     {
        //         label: t('CCQ FUNDGO'),
        //         icon: CCQIcon,
        //         fillIcon: CCQFillIcon,
        //         href: '/',
        //         showItemsOnMobile: false,
        //         items:
        //             [
        //                 {
        //                     label: t('CCQ FUNDGO'),
        //                     href: '/',
        //                     supportChainIds: [ChainId.ONUS, ChainId.ONUS_TESTNET]
        //                 },
        //             ].map((item) => addMenuItemSupported(item, chainId))
        //     },
        // ].map((item) => addMenuItemSupported(item, chainId))
        [].map((item) => addMenuItemSupported(item, chainId))
export default config
