import { createReducer } from '@reduxjs/toolkit'
import { fetchPageNumber, listItemHomeAction } from "./actions"
import { Items, ListItems } from './type'

interface global {
  listItemHome: Items[],
  pagenumbercount:number
}
export const initialState: global = {
  listItemHome:[],
  pagenumbercount:0
}
export default createReducer(initialState, (builder) =>
  builder
    .addCase(listItemHomeAction , (state, action) => {
      state.listItemHome = action.payload.listItemHome
    })
    .addCase(fetchPageNumber , (state, action) => {
      state.pagenumbercount = action.payload.pagenumbercount
    })
)