import { createReducer } from '@reduxjs/toolkit'
import { fetchPageNumber, listItemAssetsAction } from './actions'
import { Items } from './type'

interface global {
  listItemAsset: Items[],
  pagenumbercount:number
}
export const initialState: global = {
  listItemAsset:[],
  pagenumbercount:0
}
export default createReducer(initialState, (builder) =>
  builder
    .addCase(listItemAssetsAction, (state, action) => {
      state.listItemAsset = action.payload.listItemAssets
    })
    .addCase(fetchPageNumber, (state, action) => {
      state.pagenumbercount = action.payload.pagenumbercount
    })
)