import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
        <path d="M12.0002 0.333008C5.57183 0.333008 0.333496 5.57134 0.333496 11.9997C0.333496 18.428 5.57183 23.6663 12.0002 23.6663C18.4285 23.6663 23.6668 18.428 23.6668 11.9997C23.6668 5.57134 18.4285 0.333008 12.0002 0.333008ZM12.0002 16.7597C11.5218 16.7597 11.1252 16.3747 11.1252 15.8847C11.1252 15.4063 11.5218 15.0097 12.0002 15.0097C12.4902 15.0097 12.8752 15.4063 12.8752 15.8847C12.8752 16.3747 12.4902 16.7597 12.0002 16.7597ZM12.8752 12.6763C12.8752 13.1663 12.4785 13.5513 12.0002 13.5513C11.5218 13.5513 11.1252 13.1663 11.1252 12.6763V7.23967C11.1252 6.74967 11.5218 6.36467 12.0002 6.36467C12.4785 6.36467 12.8752 6.74967 12.8752 7.23967V12.6763Z"/>
    </Svg>
  );
};

export default Icon;
