import { createAction } from '@reduxjs/toolkit'
import { ListUserBalance } from 'state/asset/type'
import { ListDataChart, ListIntervest, ListItem, ListTerm, ListWrapppedItems } from './type'

export const getListItems = createAction<ListItem>('manage/getListItems')
export const getStatusLoading = createAction<{ isLoading: boolean }>('manage/getStatusLoading')
export const getTotalListing = createAction<{ totalListing: number }>('manage/getTotalListing')
export const getIntervestTermRate = createAction<{ interVestTermRate: number }>('manage/getIntervestTermRate')

export const getNumberTerm = createAction<{ totalNumberTerm: number }>('manage/getNumberTerm')
export const getItemAdvanceAction = createAction<{ nftAddress: string }>('manage/getItemAdvanceAction')
export const actionDispatchListTerm = createAction<ListTerm[]>('manage/actionDispatchListTerm');
export const fetchManagerItems = createAction<ListWrapppedItems>('manage/fetchManagerItems')
export const fetchPageNumber = createAction<{ pagenumbercount: number }>('manage/fetchPageNumber')
export const fetchPageNumberDetail = createAction<{ pagenumberDetail: number }>('manage/fetchPageNumberDetail')
export const getUserBalance = createAction<ListUserBalance>('manage/getUserBalance')
export const fetchListIntervest = createAction<ListIntervest>('manage/fetchListIntervest')

export const dataChartAction = createAction<ListDataChart>('manage/dataChartAction')
export const actionsTransactionHash = createAction<{ transactionHash: string }>('manage/actionsDispatchTransactionHash');